import { Link } from "@remix-run/react";
import { atom, useAtomValue } from "jotai";
import { Grid, Icon, Section, Text } from "sparta";
import type { State } from "~/sdk/qs1/index.server";
import stateBannerStyle from "./StateBanner.css?url";

export const stateAtom = atom<State | null>(null);

export default function StateBanner() {
  const state = useAtomValue(stateAtom);

  // check that state is not null
  if (!state || state.valid) return null;

  // if state is not null and is not invalid then "anonymous" message should not appear
  if (!state || state.valid || state.message?.includes("anonymous")) return null;

  // determine severity string
  let severity: "high" | "medium" | "low" = "low";
  switch (state.severity) {
    case 1:
      severity = "high";
      break;
    case 2:
      severity = "medium";
      break;
    default:
      severity = "low";
  }

  // Check that the state is not the "anonymous" message
  if (state.message === "anonymous") return null;
  return (
    <Section center id="state-banner" className={severity}>
      <Section justifyContent="space-between" alignItems="center">
        <Grid columns="auto 1fr" gap="5px" alignItems="center">
          <Icon name="info_outline" color={state.severity !== 2 ? "white" : "black"} />
          <Text>{state.message}</Text>
        </Grid>
        {state.action ? <Link to={state.action}>Resolve</Link> : null}
      </Section>
    </Section>
  );
}

export { stateBannerStyle };
